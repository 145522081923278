import React, { useState, useEffect, useCallback } from 'react';
import EditFoodDataModal from './ModalEditFoodData';
import MealInput from './MealInput';
import GenericMacrosModal from './ModalGenericMacros';
import { 
  apiGetFavoriteMeals, 
  apiDeleteFavoriteMeal,
  apiUpdateFavoriteMealAlias,
  apiDeleteFavoriteMealEntry,
  apiAddFoodListToFavoriteMeal,
} from '../api';
import { useFoodEditing } from '../hooks/useFoodEditing';

const MyFavoriteMeals = ({ email }) => {
  const [favoriteMeals, setFavoriteMeals] = useState([]);
  const [mealInputs, setMealInputs] = useState({});
  const [processing, setProcessing] = useState({});

  console.log("MyFavoriteMeals - final email:", email);

  const updateFavoriteMeals = useCallback((data) => {
    console.log("Updating favorite meals with:", data);
    setFavoriteMeals(data);
  }, []);

  const getFavoriteMeals = useCallback(async () => {
    if (!email) {
      console.warn('No email provided');
      return;
    }

    try {
      const data = await apiGetFavoriteMeals(email);
      console.log('Favorite meals data (detailed):', 
        data.map(meal => ({
          ...meal,
          macros: meal.macros,
          entries: meal.entries.length
        }))
      );
      setFavoriteMeals(data);
    } catch (error) {
      console.error('Error fetching favorite meals:', error);
    }
  }, [email]);

  useEffect(() => {
    if (email) {
      getFavoriteMeals();
    }
  }, [getFavoriteMeals, email]);

  const {
    selectedFood,
    setSelectedFood,
    showEditFoodDataModal,
    showGenericMacrosModal,
    handleEditFoodData,
    handleSaveFoodData,
    handleSaveGenericFoodData,
    setShowEditFoodDataModal,
    setShowGenericMacrosModal
  } = useFoodEditing(updateFavoriteMeals, 'favorites', email);

  const handleDelete = async (id) => {
    try {
      await apiDeleteFavoriteMeal(email, id);
      setFavoriteMeals(favoriteMeals.filter(meal => meal.id !== id));
    } catch (error) {
      console.error('Error deleting favorite meal:', error);
      alert('Failed to delete favorite meal. Please try again.');
    }
  };

  const handleEditAlias = async (mealId, currentAlias) => {
    const newAlias = prompt(`Enter new name for "${currentAlias}":`, currentAlias);
    if (newAlias && newAlias !== currentAlias) {
      try {
        const updatedMeal = await apiUpdateFavoriteMealAlias(email, mealId, newAlias);
        setFavoriteMeals(prevMeals => 
          prevMeals.map(meal => 
            meal.id === mealId ? { ...meal, alias: updatedMeal.alias } : meal
          )
        );
      } catch (error) {
        console.error('Error updating favorite meal alias:', error);
        alert('Failed to update favorite meal name. Please try again.');
      }
    }
  };

  const handleDeleteEntry = async (mealId, entryId) => {
    try {
        await apiDeleteFavoriteMealEntry(email, entryId);
        
        setFavoriteMeals(prevMeals => 
            prevMeals.map(meal => ({
                ...meal,
                entries: meal.entries.filter(entry => entry.id !== entryId)
            }))
        );
    } catch (error) {
      console.error('Error deleting entry:', error);
      alert('Failed to delete entry. Please try again.');
    }
  };

  const handleMealInputChange = (mealId, e) => {
    const value = e.target.value;
    setMealInputs(prev => ({
      ...prev,
      [mealId]: value
    }));
  };

  const handleExtractFoodList = async (mealId, input) => {
    if (!input?.trim()) return;
    
    setProcessing(prev => ({ ...prev, [mealId]: true }));
    try {
        const response = await apiAddFoodListToFavoriteMeal(email, mealId, input);
        setFavoriteMeals(response.favorite_meals);
        setMealInputs(prev => ({ ...prev, [mealId]: '' }));

        if (response.message) {
            alert(response.message);
        }
    } catch (error) {
        console.error('Error processing food list:', error);
        alert('Failed to process food list. Please try again.');
    } finally {
        setProcessing(prev => ({ ...prev, [mealId]: false }));
    }
  };

  return (
    <div className="dashboard-container">
      <div className="my-day-log-container">
        <div className="row header-row d-none d-xl-flex">
          <div className="col-7">Food</div>
          <div className="col-1">calories</div>
          <div className="col-1">proteins</div>
          <div className="col-1">carbs</div>
          <div className="col-1">fats</div>
          <div className="col-1"></div>
        </div>
        <div className="row header-row">
          <div className="col-7"></div>
          <div className="col-1"><i className="fas fa-fire"></i></div>
          <div className="col-1"><i className="fas fa-egg"></i></div>
          <div className="col-1"><i className="fas fa-bread-slice"></i></div>
          <div className="col-1"><i className="fas fa-cheese"></i></div>
          <div className="col-1"><i className="fas fa-trash"></i></div>
        </div>

        {favoriteMeals.map((meal) => (
          <div key={meal.id} className="meal-section mb-4">
            <div className="row meal-header align-items-center">
              <div className="col-7">
                <h3 onClick={() => handleEditAlias(meal.id, meal.alias)} style={{cursor: 'pointer'}}>
                  {meal.alias}
                </h3>
              </div>
              <div className="col-1 macro-total-cell">{Math.round(meal.macros.calories)}</div>
              <div className="col-1 macro-total-cell">{Math.round(meal.macros.proteins)}</div>
              <div className="col-1 macro-total-cell">{Math.round(meal.macros.carbohydrates)}</div>
              <div className="col-1 macro-total-cell">{Math.round(meal.macros.fats)}</div>
              <div className="col-1 action-column">
                <button 
                  onClick={() => handleDelete(meal.id)}
                  className="btn action-button-header btn-trash-meal"
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>

            {meal.entries.map((entry) => (
              <div key={entry.id} className="row meal-row align-items-center">
                <div className="col-7">
                  <span 
                    className="editable-food-log-group"
                    onClick={() => handleEditFoodData(entry)}
                    style={{cursor: 'pointer'}}
                  >
                    {entry.synonym} ({entry.quantity} {entry.units})
                  </span>
                </div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.calories)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.proteins)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.carbohydrates)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.fats)}</div>
                <div className="col-1 action-column">
                  <button 
                    onClick={() => handleDeleteEntry(meal.id, entry.id)}
                    className="btn action-button btn-trash-food"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            ))}

            <div className="row add-food-row align-items-center">
              <div className="col-11">
                <MealInput
                  key={meal.id}
                  mealType={meal.id}
                  value={mealInputs[meal.id] || ''}
                  onInputChange={handleMealInputChange}
                  onKeyDown={(mealId, e) => {
                    if (e.key === 'Enter') {
                      handleExtractFoodList(mealId, mealInputs[mealId]);
                    }
                  }}
                />
              </div>
              <div className="col-1 action-column">
                <button 
                  onClick={() => handleExtractFoodList(meal.id, mealInputs[meal.id])}
                  className="btn btn-icon"
                  disabled={processing[meal.id]}
                >
                  {processing[meal.id] ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                    <i className="fas fa-paper-plane"></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showEditFoodDataModal && (
        <EditFoodDataModal
          show={showEditFoodDataModal}
          handleClose={() => {
            setShowEditFoodDataModal(false);
            setSelectedFood(null);
          }}
          food={selectedFood}
          onSave={handleSaveFoodData}
        />
      )}

      {showGenericMacrosModal && selectedFood && (
        <GenericMacrosModal
          show={showGenericMacrosModal}
          handleClose={() => {
            setShowGenericMacrosModal(false);
            setSelectedFood(null);
          }}
          food={selectedFood}
          onSave={handleSaveGenericFoodData}
        />
      )}
    </div>
  );
};

export default MyFavoriteMeals;