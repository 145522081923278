import React, { useEffect, useRef } from 'react';

const AudioVisualizer = ({ isRecording, stream }) => {
  const canvasRef = useRef(null);
  const animationFrameId = useRef(null);
  const analyserRef = useRef(null);
  const dataPointsRef = useRef([]);

  useEffect(() => {
    if (!isRecording || !stream) {
      dataPointsRef.current = [];
      return;
    }

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);
    
    analyser.fftSize = 256;
    source.connect(analyser);
    analyserRef.current = analyser;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const draw = () => {
      if (!isRecording) return;

      animationFrameId.current = requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);

      // Calculamos el valor RMS de las frecuencias para obtener la amplitud
      const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + (val * val), 0) / dataArray.length);
      const amplitude = Math.min(rms / 128.0, 1); // Normalizado entre 0 y 1
      
      // Añadimos el nuevo punto
      dataPointsRef.current.push(amplitude);

      // Mantenemos solo los últimos N puntos que caben en el canvas
      const maxPoints = canvas.width;
      if (dataPointsRef.current.length > maxPoints) {
        dataPointsRef.current.shift();
      }

      // Limpiamos el canvas
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      // Dibujamos las barras de amplitud
      const barWidth = 2;
      const centerY = canvas.height / 2;
      
      dataPointsRef.current.forEach((amplitude, index) => {
        const x = canvas.width - (dataPointsRef.current.length - index) * barWidth;
        const height = amplitude * (canvas.height * 0.8); // 80% de la altura del canvas
        
        // Dibujamos una barra vertical
        canvasCtx.fillStyle = `rgba(33, 150, 243, ${0.3 + amplitude * 0.7})`; // Opacidad basada en amplitud
        canvasCtx.fillRect(x, centerY - height/2, barWidth, height);
      });
    };

    draw();

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      source.disconnect();
      audioContext.close();
    };
  }, [isRecording, stream]);

  return (
    <canvas 
      ref={canvasRef} 
      width="400" 
      height="30" 
      className={`recording-visualizer ${isRecording ? 'active' : ''}`}
      style={{ 
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        opacity: isRecording ? 1 : 0,
        transition: 'opacity 0.3s ease'
      }}
    />
  );
};

export default AudioVisualizer; 