import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const getFoodData = (food) => ({
    id: food?.id,
    synonym: food?.synonym,
    quantity: food?.quantity || 0,
    units: food?.units || 'g',
    food_id: food?.food_id,
    proteins_100g: food?.proteins_100g || 0,
    carbohydrates_100g: food?.carbohydrates_100g || 0,
    fats_100g: food?.fats_100g || 0,
    serving_quantity: food?.serving_quantity || 0
});

const EditFoodDataModal = ({ show, handleClose, food, onSave }) => {

    const [selectedFood, setSelectedFood] = useState(() => getFoodData(food));

    useEffect(() => {
        if (food) {
            setSelectedFood(prevFood => ({
                ...prevFood,
                ...getFoodData(food)
            }));
        }
    }, [food]);

    const [showAdvanced, setShowAdvanced] = useState(false);

    const calculateMacros = (quantity, units) => {
        const unitConversion = {
            "kg": 1000,
            "g": 1,
            "mg": 0.001,
            "µg": 0.000001,
            "l": 1000,
            "dl": 100,
            "cl": 10,
            "ml": 1,
            "servings": "servings"
        };

        if (!unitConversion.hasOwnProperty(units)) {
            console.error(`Invalid units '${units}'`);
            return {
                proteins: 0,
                carbohydrates: 0,
                fats: 0
            };
        }

        if (units === "servings") {
            // Calculamos primero los macros por serving
            const macrosPerServing = {
                proteins: (selectedFood.serving_quantity * selectedFood.proteins_100g) / 100,
                carbohydrates: (selectedFood.serving_quantity * selectedFood.carbohydrates_100g) / 100,
                fats: (selectedFood.serving_quantity * selectedFood.fats_100g) / 100
            };
            
            const factor = quantity / 1;
            return {
                proteins: macrosPerServing.proteins * factor,
                carbohydrates: macrosPerServing.carbohydrates * factor,
                fats: macrosPerServing.fats * factor
            };
        } else {
            const factor = quantity * unitConversion[units] / 100;
            return {
                proteins: selectedFood.proteins_100g * factor,
                carbohydrates: selectedFood.carbohydrates_100g * factor,
                fats: selectedFood.fats_100g * factor
            };
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedFood(prev => ({
            ...prev,
            [name]: name === 'units' ? value : (name === 'synonym' ? value : (parseFloat(value) || 0))
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(selectedFood);
      };

    return (
        <Modal show={show} onHide={handleClose} size="sm">
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <small className="text-muted mb-2 d-block">
                        {selectedFood.synonym}
                    </small>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            name="synonym"
                            value={selectedFood.synonym}
                            onChange={handleInputChange}
                            placeholder="Nuevo nombre"
                            autoFocus

                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <div className="d-flex align-items-center">
                            <Form.Control
                                type="number"
                                name="quantity"
                                value={selectedFood.quantity}
                                onChange={handleInputChange}
                                placeholder="Cantidad"
                                className="me-2"
                                style={{width: '70%'}}
                            />
                            <Form.Control
                                as="select"
                                name="units"
                                value={selectedFood.units}
                                onChange={handleInputChange}
                                style={{width: '30%'}}
                            >
                                <option value="g">g</option>
                                <option value="cl">cl</option>
                                <option value="ml">ml</option>
                                <option value="servings">servings</option>
                                
                            </Form.Control>
                        </div>
                    </Form.Group>
                    
                    <div className="d-flex align-items-center justify-content-center mb-3">
                        <small className="text-muted me-2">Macros</small>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="macrosSwitch"
                                checked={showAdvanced}
                                onChange={() => setShowAdvanced(!showAdvanced)}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>

                    {showAdvanced && (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div className="text-center" style={{width: '30%'}}>
                                    <div className="mb-1">
                                        <i className="fas fa-egg macro-icon"></i> P.
                                    </div>
                                    <Form.Control
                                        type="number"
                                        step="0.1"
                                        name="proteins_100g"
                                        value={selectedFood.proteins_100g}
                                        onChange={handleInputChange}
                                        className="text-center"
                                        style={{width: '100%'}}
                                    />
                                    <small className="text-muted mt-1">
                                        {calculateMacros(selectedFood.quantity, selectedFood.units).proteins.toFixed(1)}g
                                    </small>
                                </div>
                                <div className="text-center" style={{width: '30%'}}>
                                    <div className="mb-1">
                                        <i className="fas fa-bread-slice macro-icon"></i> C.
                                    </div>
                                    <Form.Control
                                        type="number"
                                        step="0.1"
                                        name="carbohydrates_100g"
                                        value={selectedFood.carbohydrates_100g}
                                        onChange={handleInputChange}
                                        className="text-center"
                                        style={{width: '100%'}}
                                    />
                                    <small className="text-muted mt-1">
                                        {calculateMacros(selectedFood.quantity, selectedFood.units).carbohydrates.toFixed(1)}g
                                    </small>
                                </div>
                                <div className="text-center" style={{width: '30%'}}>
                                    <div className="mb-1">
                                        <i className="fas fa-cheese macro-icon"></i> G.
                                    </div>
                                    <Form.Control
                                        type="number"
                                        step="0.1"
                                        name="fats_100g"
                                        value={selectedFood.fats_100g}
                                        onChange={handleInputChange}
                                        className="text-center"
                                        style={{width: '100%'}}
                                    />
                                    <small className="text-muted mt-1">
                                        {calculateMacros(selectedFood.quantity, selectedFood.units).fats.toFixed(1)}g
                                    </small>
                                </div>
                            </div>

                            {selectedFood.units === "servings" && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Serving size (g)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.1"
                                        name="serving_quantity"
                                        value={selectedFood.serving_quantity}
                                        onChange={handleInputChange}
                                        className="text-center"
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}

                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" size="sm" onClick={handleClose} className="me-2">
                            Cancelar
                        </Button>
                        <Button variant="primary" size="sm" onClick={handleSubmit}>
                            Guardar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditFoodDataModal;