class VoiceService {
  constructor() {
    this.mediaRecorder = null;
    this.audioChunks = [];
    this.stream = null;
  }

  async requestMicrophonePermission() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return this.stream;
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
      return null;
    }
  }

  async startRecording(onError) {
    try {
      const stream = await this.requestMicrophonePermission();
      if (!stream) {
        const errorMessage = 'No se pudo acceder al micrófono. Por favor:\n' +
          '1. Verifica que tu dispositivo tiene micrófono\n' +
          '2. Permite el acceso al micrófono cuando se solicite';
        onError(errorMessage);
        throw new Error(errorMessage);
      }

      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm'
      });
      
      this.audioChunks = [];

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.start();
      return true;
    } catch (error) {
      onError('Error al iniciar la grabación');
      return false;
    }
  }

  stopAllTracks() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
        this.stream.removeTrack(track);
      });
      this.stream = null;
    }
  }

  stopRecording() {
    return new Promise((resolve, reject) => {
      if (!this.mediaRecorder) {
        reject('No hay grabación activa');
        return;
      }

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.stopAllTracks();
        resolve(audioBlob);
      };

      this.mediaRecorder.stop();
    });
  }
}

export default new VoiceService(); 