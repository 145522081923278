import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const GenericMacrosModal = ({ show, handleClose, mealType, onSave, food = null }) => {
  const [selectedFood, setSelectedFood] = useState({
    id: null,
    name: '',
    quantity: 100,
    units: 'g',
    proteins: 0,
    carbohydrates: 0,
    fats: 0
  });

  useEffect(() => {
    console.log("GenericMacrosModal - food received:", food);
    
    if (food) {
      setSelectedFood({
        id: food.id,
        name: food.custom_name || food.name,
        quantity: food.quantity,
        units: food.units,
        proteins: food.macros?.proteins || 0,
        carbohydrates: food.macros?.carbohydrates || 0,
        fats: food.macros?.fats || 0
      });
      console.log("GenericMacrosModal - selectedFood set to:", selectedFood);
    } else {
      // Reset cuando no hay datos iniciales
      setSelectedFood({
        id: null,
        name: '',
        quantity: 100,
        units: 'g',
        proteins: 0,
        carbohydrates: 0,
        fats: 0
      });
    }
  }, [food]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedFood(prev => ({
      ...prev,
      [name]: name === 'name' ? value : (parseFloat(value) || 0)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const foodData = {
      ...selectedFood,
      meal_type: mealType // Needed when adding generic food data
    };
    console.log("Submitting food data:", foodData);
    onSave(foodData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="name"
              value={selectedFood.name}
              onChange={handleInputChange}
              placeholder="Nombre del alimento"
              autoFocus
            />
          </Form.Group>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-center" style={{width: '30%'}}>
              <div className="mb-1">
                <i className="fas fa-egg macro-icon"></i> P.
              </div>
              <Form.Control
                type="number"
                step="0.1"
                name="proteins"
                value={selectedFood.proteins}
                onChange={handleInputChange}
                className="text-center"
                style={{width: '100%'}}
              />
            </div>
            <div className="text-center" style={{width: '30%'}}>
              <div className="mb-1">
                <i className="fas fa-bread-slice macro-icon"></i> C.
              </div>
              <Form.Control
                type="number"
                step="0.1"
                name="carbohydrates"
                value={selectedFood.carbohydrates}
                onChange={handleInputChange}
                className="text-center"
                style={{width: '100%'}}
              />
            </div>
            <div className="text-center" style={{width: '30%'}}>
              <div className="mb-1">
                <i className="fas fa-cheese macro-icon"></i> G.
              </div>
              <Form.Control
                type="number"
                step="0.1"
                name="fats"
                value={selectedFood.fats}
                onChange={handleInputChange}
                className="text-center"
                style={{width: '100%'}}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose} className="me-2">
              Cancelar
            </Button>
            <Button variant="primary" size="sm" type="submit">
              {food ? 'Guardar' : 'Añadir'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default GenericMacrosModal;