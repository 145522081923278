import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarCustomInput from './CalendarCustomInput';
import MacroProgressBars from './MacroProgressBars';
import MealInput from './MealInput';
import GenericMacrosModal from './ModalGenericMacros';
import ManualEntryModal from './ModalManualEntry';
import EditFoodDataModal from './ModalEditFoodData';
import MrFitAdvisor from './MrFitAdvisor';
import {
  apiLogFoodInMeal,
  apiDeleteFoodLogEntry,
  apiClearMeal,
  apiGetOrCreateDayLog,
  apiGetUserEntries,
  apiAddFavoriteMeal, 
  apiGetFavoriteMeals, 
  apiLogFavoriteMeal,
  apiAddManualEntry,
  apiGetDatesWithEntries
} from '../api';
import { useFoodEditing } from '../hooks/useFoodEditing';
import '../styles/Dashboard.css';
import voiceService from '../services/voiceService';

function Dashboard({
    navigate,
    userEmail,
    isLoggedIn,
    setIsLoggedIn,
    loginData,
    setLoginData,
    handleLogout,
    logUpdateCounter,
}) {
    const [input, setInput] = useState('');
    const [processing, setProcessing] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedMealType, setSelectedMealType] = useState(null);
    const [favoriteMeals, setFavoriteMeals] = useState([]);
    const [showManualEntryModal, setShowManualEntryModal] = useState(false);
    const [selectedMealTypeForGeneric, setSelectedMealTypeForGeneric] = useState(null);
    const [selectedMealTypeForManual, setSelectedMealTypeForManual] = useState(null);
    const [userEntries, setUserEntries] = useState({});
    const [mealTypes, setMealTypes] = useState([]);
    const [suggestions, setSuggestions] = useState({});
    const [mealInputs, setMealInputs] = useState(() => {
    const initialInputs = {};
      mealTypes.forEach((mealType) => {
        initialInputs[mealType] = '';
      });
      return initialInputs;
    });
  
    const [dayLog, setDayLog] = useState({
      breakfast: [],
      morning_extra: [],
      lunch: [],
      afternoon_extra: [],
      dinner: [],
      other: [],
    });
  
    const [totalMacros, setTotalMacros] = useState({
      calories: 0,
      proteins: 0,
      carbohydrates: 0,
      fats: 0,
    });
  
    const [macrosGoals, setMacrosGoals] = useState({
      calories: 0,
      proteins: 0,
      carbohydrates: 0,
      fats: 0,
    });
  
    const [datesWithEntries, setDatesWithEntries] = useState([]);
    const [collapsedFavorites, setCollapsedFavorites] = useState({});
    const [activeMealOptions, setActiveMealOptions] = useState(null);
  
    const updateDailyInfos = (response) => {
        // Si la respuesta viene con day_log, usamos eso
        const daylog = response.day_log || response;
        
        console.log("daylog received to Dashboard", daylog);
        setDayLog(daylog.log);
        setTotalMacros(daylog.macros);
        setMacrosGoals(daylog.goals);
        setMealTypes(daylog.meal_names);

        // Añadir la fecha actual a datesWithEntries si no existe y hay entradas
        const currentDateStr = selectedDate.toISOString().split('T')[0];
        const hasEntries = Object.values(daylog.log).some(meal => meal.length > 0);
        
        if (hasEntries) {
            setDatesWithEntries(prev => {
                const dateExists = prev.some(date => 
                    date.toISOString().split('T')[0] === currentDateStr
                );
                if (!dateExists) {
                    console.log('Añadiendo nueva fecha al calendario:', currentDateStr);
                    const newDate = new Date(currentDateStr + 'T00:00:00.000Z');
                    return [...prev, newDate];
                }
                return prev;
            });
        }
    };

    const {
        selectedFood,
        setSelectedFood,
        showEditFoodDataModal,
        showGenericMacrosModal,
        handleEditFoodData,
        handleSaveFoodData,
        handleSaveGenericFoodData,
        setShowEditFoodDataModal,
        setShowGenericMacrosModal
    } = useFoodEditing(updateDailyInfos, 'daylog', userEmail, selectedDate.toISOString().split('T')[0]);
  
    const handleMealInputChange = (mealType, e) => {
      const value = e.target.value;
    
      setMealInputs((prevInputs) => ({
        ...prevInputs,
        [mealType]: value,
      }));
    
      const entries = value.split(',');
      const lastEntry = entries[entries.length - 1];
      const currentInput = value.endsWith(',') ? '' : lastEntry.trim().toLowerCase();
    
      let suggestion = '';
    
      if (currentInput.length > 0) {
        // Buscar primero en el mismo meal_type
        const sameMealTypeEntries = Object.values(userEntries).filter(
          entry => entry.meal_type === mealType && entry.food_name.toLowerCase().startsWith(currentInput)
        );
        
        if (sameMealTypeEntries.length > 0) {
          const mostFrequent = sameMealTypeEntries.reduce((prev, current) => 
            (prev.frequency > current.frequency) ? prev : current
          );
          suggestion = mostFrequent.food_name.slice(currentInput.length);
        } else {
          // Si no hay coincidencias, buscar en otros meal_types
          const otherMealTypeEntries = Object.values(userEntries).filter(
            entry => entry.food_name.toLowerCase().startsWith(currentInput)
          );
          
          if (otherMealTypeEntries.length > 0) {
            const mostFrequent = otherMealTypeEntries.reduce((prev, current) => 
              (prev.frequency > current.frequency) ? prev : current
            );
            suggestion = mostFrequent.food_name.slice(currentInput.length);
          }
        }
      }
    
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [mealType]: suggestion,
      }));
    };
  
    const handleKeyDown = (mealType, e) => {
      if (suggestions[mealType]) {
        if (e.key === 'Tab' || e.key === 'ArrowRight') {
          e.preventDefault();
          const value = mealInputs[mealType] || '';
          const entries = value.split(',');
          const lastEntry = entries[entries.length - 1];
          const currentInput = value.endsWith(',') ? '' : lastEntry.trim();
  
          // Aceptar la sugerencia
          entries[entries.length - 1] = currentInput + suggestions[mealType];
          const newValue = entries.join(', ');
  
          setMealInputs((prevInputs) => ({
            ...prevInputs,
            [mealType]: newValue,
          }));
  
          // Limpiar la sugerencia
          setSuggestions((prevSuggestions) => ({
            ...prevSuggestions,
            [mealType]: '',
          }));
        }
      }
    };
  
    const handleLogFoodInMeal = async (mealType, message) => {
      if (!message.trim()) {
        return;
      }
      if (!mealTypes.includes(mealType)) {
        console.error(`Invalid meal type: ${mealType}. Valid types are: ${mealTypes.join(', ')}`);
        return;
      }
      setProcessing((prevState) => ({
        ...prevState,
        [mealType]: `Processing input for ${mealType}`,
      }));
      try {
        const data = await apiLogFoodInMeal(
          mealType, 
          message, 
          userEmail, 
          selectedDate.toISOString().split('T')[0]
        );
        updateDailyInfos(data.day_log);
        if (data.message) {
          alert(data.message);
        }
        setMealInputs((prevState) => ({
          ...prevState,
          [mealType]: '',
        }));
      } catch (error) {
        console.error('Error logging food:', error);
        alert(`Failed to log food: ${error.message}`);
      } finally {
        setProcessing((prevState) => ({
          ...prevState,
          [mealType]: '',
        }));
      }
    };
  
    const handleClearMealClick = async (mealType) => {
      const data = await apiClearMeal(
        userEmail, 
        mealType,
        selectedDate.toISOString().split('T')[0]
      );
      updateDailyInfos(data);
    };
  
    const handleAddFavoriteMeal = async (mealType) => {
      const alias = prompt("Enter a name for this favorite meal:");
      if (alias) {
        try {
          await apiAddFavoriteMeal(
            userEmail, 
            alias, 
            selectedDate.toISOString().split('T')[0], 
            mealType
          );
          fetchFavoriteMeals();
          alert("Meal added to favorites successfully!");
        } catch (error) {
          console.error('Error adding favorite meal:', error);
          alert('Failed to add favorite meal. Please try again.');
        }
      }
    };
  
    const handleOpenGenericMacrosModal = (mealType) => {
      setSelectedMealTypeForGeneric(mealType);
      setShowGenericMacrosModal(true);
    };

  
    const handleOpenManualEntryModal = (mealType) => {
      setSelectedMealTypeForManual(mealType);
      setShowManualEntryModal(true);
    };

  
    const handleAddManualEntry = async (mealType, entryData) => {
      try {
        setProcessing(prevState => ({ ...prevState, [mealType]: 'Añadiendo entrada manual...' }));
        const data = await apiAddManualEntry(
          userEmail,
          selectedDate.toISOString().split('T')[0],
          mealType,
          entryData.name,
          entryData.macros,
          entryData.quantity,
          entryData.units
        );
        updateDailyInfos(data);
        setProcessing(prevState => ({ ...prevState, [mealType]: '' }));
      } catch (error) {
        console.error('Error:', error);
        setProcessing(prevState => ({ ...prevState, [mealType]: 'Error al añadir entrada manual' }));
      }
    };

    const handleSelectFavoriteForLog = (mealType) => {
      setSelectedMealType(mealType);
      navigate(`/select-favorite-meal/${selectedDate.toISOString().split('T')[0]}/${mealType}`);
    };

  
    const handleAddFavoriteToLog = async (mealId, mealType, date) => {
      console.log("Adding favorite meal to log:", mealId, mealType, date);
      try {
        const data = await apiLogFavoriteMeal(
          userEmail, 
          mealId, 
          date, 
          mealType
        );
        updateDailyInfos(data);
        navigate('/');
      } catch (error) {
        console.error('Error logging favorite meal:', error);
        alert('Failed to log favorite meal. Please try again.');
      }
    };
  
    const fetchFavoriteMeals = useCallback(async () => {
      const meals = await apiGetFavoriteMeals(userEmail);
      setFavoriteMeals(meals);
    }, []); // Sin dependencias
    
    useEffect(() => {
        if (isLoggedIn) {
            fetchFavoriteMeals();
        }
    }, [isLoggedIn, userEmail]);


    const fetchUserEntries = async () => {
      try {
        const entries = await apiGetUserEntries(userEmail);
        setUserEntries(entries);
      } catch (error) {
        console.error('Error fetching user entries:', error);
      }
    };
  
    const getTotalMacrosForMeal = (mealItems) => {
      const totals = mealItems.reduce((totals, item) => {
        totals.calories += item.macros.calories;
        totals.proteins += item.macros.proteins;
        totals.carbohydrates += item.macros.carbohydrates;
        totals.fats += item.macros.fats;
        return totals;
      }, { calories: 0, proteins: 0, carbohydrates: 0, fats: 0 });
    
      return {
        calories: Math.round(totals.calories),
        proteins: Math.round(totals.proteins),
        carbohydrates: Math.round(totals.carbohydrates),
        fats: Math.round(totals.fats),
      };
    };
    
    const handleLogoutClick = () => {
      localStorage.removeItem('user');
      setIsLoggedIn(false);
      setLoginData({ email: '', password: '' });
      navigate('/'); // Esto nos llevará de vuelta a la página de inicio
    };
  
    const handlePreviousDayClick = () => {
      const previousDay = new Date(selectedDate);
      previousDay.setDate(selectedDate.getDate() - 1);
      setSelectedDate(previousDay);
    };
  
    const handleNextDayClick = () => {
      const nextDay = new Date(selectedDate);
      nextDay.setDate(selectedDate.getDate() + 1);
      setSelectedDate(nextDay);
    };
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
    
    const handleDeleteLogEntryClick = async (entryId) => {
      try {
        const data = await apiDeleteFoodLogEntry(
          userEmail,
          selectedDate.toISOString().split('T')[0],
          entryId
        );
        updateDailyInfos(data);
        
        // Si el daylog está vacío después de borrar, actualizar datesWithEntries
        if (Object.values(data.log).every(meal => meal.length === 0)) {
          setDatesWithEntries(prev => 
            prev.filter(date => 
              date.toISOString().split('T')[0] !== selectedDate.toISOString().split('T')[0]
            )
          );
        }
      } catch (error) {
        console.error('Error deleting food entry:', error);
        alert('Failed to delete food entry. Please try again.');
      }
    };
      
    //////////////////////////////////////////////////////////////////////////////
    // EFFECTS
    //////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
      console.log("call fetchDayLog");
      const fetchDayLog = async () => {
        try {
          const formattedDate = selectedDate.toISOString().split('T')[0];
          const daylog = await apiGetOrCreateDayLog(userEmail, formattedDate);
          updateDailyInfos(daylog);
        } catch (error) {
          console.error('Error fetching day info:', error);
        }
      };
      fetchDayLog();
    }, [logUpdateCounter, selectedDate, userEmail]);
  
    useEffect(() => {
      console.log("call fetchUserEntries");
      fetchUserEntries();
    }, [userEmail]);
  
    useEffect(() => {
      fetchFavoriteMeals();
    }, [fetchFavoriteMeals]);
    
    useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setIsLoggedIn(true);
        setLoginData({ email: user.email, password: '' });
      }
    }, []);
  
    useEffect(() => {
        const fetchDatesWithEntries = async () => {
            try {
                const data = await apiGetDatesWithEntries(userEmail);
                // Opción 1: Usar fechas a medianoche UTC
                const dates = data.dates.map(dateStr => {
                    const date = new Date(dateStr + 'T00:00:00.000Z');
                    return date;
                });
                console.log('Fechas para highlight:', dates);
                setDatesWithEntries(dates);
            } catch (error) {
                console.error('Error fetching dates with entries:', error);
            }
        };
        fetchDatesWithEntries();
    }, [userEmail]);
  
    const toggleFavoriteCollapse = (favoriteId) => {
        setCollapsedFavorites(prev => ({
            ...prev,
            [favoriteId]: !prev[favoriteId]
        }));
    };

    const renderMealEntries = (mealType, entries) => {
        // La lógica de agrupación se mantiene igual
        const groupedEntries = entries.reduce((acc, entry) => {
            if (entry.from_favorite) {
                if (!acc[entry.from_favorite.id]) {
                    acc[entry.from_favorite.id] = {
                        isFavorite: true,
                        alias: entry.from_favorite.alias,
                        entries: []
                    };
                }
                acc[entry.from_favorite.id].entries.push(entry);
            } else {
                if (!acc[-1]) acc[-1] = { isFavorite: false, entries: [] };
                acc[-1].entries.push(entry);
            }
            return acc;
        }, {});

        const renderEntry = (entry) => (
            <div key={entry.id} className="row meal-row align-items-center">
                <div className="col-7">
                    <span 
                        className="editable-food-log-group"
                        onClick={() => handleEditFoodData(entry)}
                    >
                        {entry.synonym} ({entry.quantity} {entry.units})
                    </span>
                </div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.calories)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.proteins)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.carbohydrates)}</div>
                <div className="col-1 macro-cell">{Math.round(entry.macros.fats)}</div>
                <div className="col-1 action-column">
                    <div className="action-button-container">
                        <button 
                            onClick={() => handleDeleteLogEntryClick(entry.id)}
                            className="btn action-button btn-trash-food"
                        >
                            <i className="fas fa-trash action-icon"></i>
                        </button>
                    </div>
                </div>
            </div>
        );

        return Object.entries(groupedEntries).map(([groupId, group]) => (
            <React.Fragment key={groupId}>
                {group.isFavorite ? (
                    <div className="favorite-meal-group">
                        <div className="row meal-row align-items-center" 
                             onClick={() => toggleFavoriteCollapse(groupId)}>
                            <div className="col-7" style={{cursor: 'pointer', backgroundColor: '#f8f9fa'}}>
                                <span className="favorite-meal-title">{group.alias}</span>
                            </div>
                            {collapsedFavorites[groupId] && (
                                <>
                                    <div className="col-1 macro-cell">{getTotalMacrosForMeal(group.entries).calories}</div>
                                    <div className="col-1 macro-cell">{getTotalMacrosForMeal(group.entries).proteins}</div>
                                    <div className="col-1 macro-cell">{getTotalMacrosForMeal(group.entries).carbohydrates}</div>
                                    <div className="col-1 macro-cell">{getTotalMacrosForMeal(group.entries).fats}</div>
                                </>
                            )}
                        </div>
                        {!collapsedFavorites[groupId] && (
                            <div className="favorite-meal-entries ps-2" onClick={(e) => e.stopPropagation()}>
                                {group.entries.map(renderEntry)}
                            </div>
                        )}
                    </div>
                ) : (
                    group.entries.map(renderEntry)
                )}
            </React.Fragment>
        ));
    };
  
    const setShowMealOptions = (mealType) => {
        setActiveMealOptions(activeMealOptions === mealType ? null : mealType);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.meal-options') && !event.target.closest('.btn-more-options')) {
                setActiveMealOptions(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
  
    const handleVoiceInput = async (mealType, text) => {
        handleMealInputChange(mealType, { target: { value: text } });
    };
  
    return (


            <div className="dashboard-container">
            <div className="date-navigation mb-3 d-flex justify-content-center align-items-center">
                <button className="btn btn-outline-secondary" onClick={handlePreviousDayClick}>
                    <i className="fa fa-chevron-left"></i>
                </button>
                <div className="mx-3">
                    {selectedDate.toDateString()}
                </div>
                <button className="btn btn-outline-secondary" onClick={handleNextDayClick}>
                    <i className="fa fa-chevron-right"></i>
                </button>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    customInput={<CalendarCustomInput />}
                    dateFormat="yyyy-MM-dd"
                    todayButton="Today"
                    highlightDates={datesWithEntries}
                />
                </div>
                
                <div className="my-day-log-container">
                <div className="row header-row d-none d-xl-flex">
                    <div className="col-7"></div>
                    <div className="col-1">calories</div>
                    <div className="col-1">proteins</div>
                    <div className="col-1">carbs</div>
                    <div className="col-1">fats</div>
                    <div className="col-1"></div>
                </div>
                <div className="row header-row">
                    <div className="col-7"></div>
                    <div className="col-1"><i className="fas fa-fire"></i></div>
                    <div className="col-1"><i className="fas fa-egg"></i></div>
                    <div className="col-1"><i className="fas fa-bread-slice"></i></div>
                    <div className="col-1"><i className="fas fa-cheese"></i></div>
                    <div className="col-1"><i className="fas fa-trash"></i></div>
                </div>
                <div className="row header-row-total-macros">
                    <div className="col-2 offset-5 total-macros-row-title"></div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.calories)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.proteins)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.carbohydrates)}</div>
                    <div className="col-1 macro-total-cell-day">{Math.round(totalMacros.fats)}</div>
                </div>

                {mealTypes.map((mealType) => (
                    
                    <div key={mealType} className="meal-section mb-4">
                    <div className="row meal-header">
                        <div className="col-4"><h3>{mealType}</h3></div>
                        <div className="col-3 justify-content-end d-flex position-relative">
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowMealOptions(mealType);
                                }}
                                className="btn btn-outline-secondary btn-sm btn-icon btn-more-options"
                                title="More options"
                            >
                                <i className="fas fa-ellipsis-h"></i>
                            </button>
                            
                            <div className={`meal-options ${activeMealOptions === mealType ? 'show' : ''}`}>
                                <button 
                                    onClick={() => handleAddFavoriteMeal(mealType)}
                                    className="btn btn-outline-secondary btn-sm btn-icon"
                                    title="Add to favorites"
                                >
                                    <i className="fas fa-star"></i>
                                    <span className="ms-2">Add to favorites</span>
                                </button>
                                <button 
                                    onClick={() => handleSelectFavoriteForLog(mealType)}
                                    className="btn btn-outline-secondary btn-sm btn-icon"
                                    title="Add favorite to log"
                                >
                                    <i className="fas fa-plus"></i>
                                    <span className="ms-2">Add favorite to log</span>
                                </button>
                                <button
                                    onClick={() => handleOpenManualEntryModal(mealType)}
                                    className="btn btn-outline-secondary btn-sm btn-icon"
                                    title="Add manual entry"
                                >
                                    <i className="fas fa-pencil-alt"></i>
                                    <span className="ms-2">Manual entry</span>
                                </button>
                                <button 
                                    onClick={() => handleOpenGenericMacrosModal(mealType)}
                                    className="btn btn-outline-secondary btn-sm btn-icon"
                                    title="Add generic macros"
                                >
                                    <i className="fas fa-calculator"></i>
                                    <span className="ms-2">Generic macros</span>
                                </button>
                            </div>
                        </div>

                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).calories}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).proteins}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).carbohydrates}</div>
                        <div className="col-1 macro-total-cell">{getTotalMacrosForMeal(dayLog[mealType]).fats}</div>
                        <div className="col-1 action-column">
                        <div className="action-button-container">
                            <button 
                            onClick={() => handleClearMealClick(mealType)} 
                            className="btn action-button-header btn-trash-meal"
                            >
                            <i className="fa fa-trash action-icon"></i>
                            </button>
                        </div>
                        </div>
                    </div>
                    {Array.isArray(dayLog[mealType]) && renderMealEntries(mealType, dayLog[mealType])}
                    <div className="row add-food-row align-items-center">
                        <div className="col-11">
                            <MealInput
                                key={mealType}
                                mealType={mealType}
                                value={mealInputs[mealType] || ''}
                                suggestion={suggestions[mealType]}
                                onInputChange={handleMealInputChange}
                                onKeyDown={(mealType, e) => {
                                    if (e.isEnter) {
                                        handleLogFoodInMeal(mealType, mealInputs[mealType]);
                                    } else {
                                        handleKeyDown(mealType, e);
                                    }
                                }}
                                onVoiceInput={handleVoiceInput}
                                userEmail={userEmail}
                            />
                        </div>
                        <div className="col-1 action-column">
                            <div className="action-button-container">
                                <button 
                                    onClick={() => handleLogFoodInMeal(mealType, mealInputs[mealType])} 
                                    className="btn btn-icon"
                                    title="Add food"
                                    disabled={processing[mealType]}
                                >
                                    {processing[mealType] ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <i className="fas fa-paper-plane"></i>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    </div> 
                ))}

                <div className="summary-container">
                    <MacroProgressBars 
                    totalMacros={totalMacros} 
                    macrosGoals={macrosGoals}
                    />
                </div> {/* summary-container */}
                </div> {/* my-day-log-container */}

                <div className='row'>
                <div className='col-12'>
                    <MrFitAdvisor 
                        date={selectedDate.toISOString().split('T')[0]}
                        dayLog={dayLog}
                        totalMacros={totalMacros}
                        macrosGoals={macrosGoals}
                    />
                </div>
            </div>
            {showEditFoodDataModal && (
              <EditFoodDataModal
                  show={showEditFoodDataModal}
                  handleClose={() => {
                      setShowEditFoodDataModal(false);
                      setSelectedFood(null);
                  }}
                  food={selectedFood}
                  onSave={handleSaveFoodData}
              />
            )}
            {showGenericMacrosModal && (
              <GenericMacrosModal 
                show={showGenericMacrosModal}
                handleClose={() => {
                  setShowGenericMacrosModal(false);
                  setSelectedFood(null);
                }}
                mealType={selectedMealTypeForGeneric}
                food={selectedFood}
                onSave={handleSaveGenericFoodData}
              />
            )}
            {showManualEntryModal && (
              <ManualEntryModal 
                show={showManualEntryModal}
                handleClose={() => setShowManualEntryModal(false)}
                mealType={selectedMealTypeForManual}
                onSubmit={handleAddManualEntry}
              />
            )}
            </div>
    );
}

export default Dashboard;