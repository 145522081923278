import { useState } from 'react';
import { 
    apiUpdateFoodData, 
    apiUpdateGenericFoodData,
    apiUpdateGenericFoodDataFavorite,
    apiUpdateFavoriteFoodData,
    apiFetchFoodInfo,
    apiAddGenericFoodData
} from '../api';

export const useFoodEditing = (onUpdateSuccess, context = 'daylog', email, date) => {
    const [selectedFood, setSelectedFood] = useState(null);
    const [showEditFoodDataModal, setShowEditFoodDataModal] = useState(false);
    const [showGenericMacrosModal, setShowGenericMacrosModal] = useState(false);

    const handleEditFoodData = async (logEntry) => {
        console.log("logEntry completo:", logEntry);
        
        if (!logEntry.food_id) {
            // Comida genérica
            const initialFoodData = {
                id: logEntry.id,
                custom_name: logEntry.custom_name,
                meal_type: logEntry.meal_type,
                quantity: logEntry.quantity,
                units: logEntry.units,
                macros: logEntry.generic_macros,
            };
            setSelectedFood(initialFoodData);
            setShowGenericMacrosModal(true);
            setShowEditFoodDataModal(false);
        } else {
            // Comida normal - Primero mostramos datos básicos
            const initialFoodData = {
                id: logEntry.id,
                food_id: logEntry.food_id,
                synonym: logEntry.synonym,
                meal_type: logEntry.meal_type,
                quantity: logEntry.quantity || 0,
                units: logEntry.units || 'g',
                proteins_100g: 0,
                carbohydrates_100g: 0,
                fats_100g: 0,
                serving_quantity: 0
            };
            setSelectedFood(initialFoodData);
            setShowEditFoodDataModal(true);
            setShowGenericMacrosModal(false);

            // Cargamos macros en segundo plano
            try {
                const foodInfoResponse = await apiFetchFoodInfo(email);
                const foodInfo = foodInfoResponse.find(food => food.id === logEntry.food_id);
                
                if (foodInfo) {
                    setSelectedFood(prevFood => ({
                        ...prevFood,
                        proteins_100g: foodInfo.proteins_100g,
                        carbohydrates_100g: foodInfo.carbohydrates_100g,
                        fats_100g: foodInfo.fats_100g,
                        serving_quantity: foodInfo.serving_quantity
                    }));
                }
            } catch (error) {
                console.error('Error loading detailed food info:', error);
            }
        }
    };

    const handleSaveFoodData = async (newFoodData) => {
        try {
            console.log("Datos originales:", newFoodData);
            const foodData = {
                ...newFoodData,
                source: 'user',
                email: email
            };
            
            let data;
            if (context === 'favorites') {
                console.log("Guardando comida en favoritos:", foodData);
                data = await apiUpdateFavoriteFoodData(email, foodData);
            } else {
                console.log("Guardando comida normal:", foodData);
                console.log("Fecha:", date);
                data = await apiUpdateFoodData(email, date, foodData);
            }
            
            console.log("Respuesta del servidor:", data);
            onUpdateSuccess(data);
            setShowEditFoodDataModal(false);
            setSelectedFood(null);
        } catch (error) {
            console.error('Error completo:', error);
            alert('Error updating/adding food. Please try again.');
        }
    };

    const handleSaveGenericFoodData = async (newFoodData) => {
        try {
            console.log("Datos originales:", newFoodData);
            const foodData = {
                id: newFoodData.id,
                custom_name: newFoodData.name,
                meal_type: newFoodData.meal_type,  // Añadimos meal_type
                quantity: newFoodData.quantity,
                units: newFoodData.units,
                macros: {
                    proteins: newFoodData.proteins,
                    carbohydrates: newFoodData.carbohydrates,
                    fats: newFoodData.fats
                },
                source: 'user',
            };
            
            let data;
            if (context === 'favorites') {
                console.log("Actualizando comida genérica en favoritos:", foodData);
                data = await apiUpdateGenericFoodDataFavorite(email, foodData);
            } else {
                console.log("Guardando comida genérica en daylog:", foodData);
                console.log("Fecha:", date);
                if (foodData.id) {
                    data = await apiUpdateGenericFoodData(email, date, foodData);
                } else {
                    data = await apiAddGenericFoodData(email, date, foodData);
                }
            }
            
            console.log("Respuesta del servidor:", data);
            onUpdateSuccess(data);
            setShowGenericMacrosModal(false);
            setSelectedFood(null);
        } catch (error) {
            console.error('Error completo:', error);
            alert('Error updating/adding food. Please try again.');
        }
    };

    return {
        selectedFood,
        setSelectedFood,
        showEditFoodDataModal,
        showGenericMacrosModal,
        handleEditFoodData,
        handleSaveFoodData,
        handleSaveGenericFoodData,
        setShowEditFoodDataModal,
        setShowGenericMacrosModal
    };
};
