import React, { useState, useEffect } from 'react';
import { apiFetchGoals, apiSaveGoals } from '../api';
import '../styles/MyGoals.css';
function MyGoals({ email }) {
  const [goals, setGoals] = useState({
    calories: '',
    proteins: '',
    carbohydrates: '',
    fats: '',
    weight: '',
    height: '',
    age: '',
    gender: 'male',
    body_fat: '',
    lifestyle: '',
    exercise_mets: '',
    exercise_duration: ''
  });

  const [bmr, setBmr] = useState({
    mifflin: null,
    katch: null
  });

  const [tdee, setTdee] = useState({
    activityLevel: '',
    exercise_mets: '',
    exercise_duration: 0,
    exerciseCalories: 0,
    total: 0
  });

  const [macroCalculator, setMacroCalculator] = useState({
    goal: '',
    targetCalories: 0,
    proteins: 30,
    carbs: 35,
    fats: 35
  });

  useEffect(() => {
    const getGoals = async () => {
      const goalsData = await apiFetchGoals(email);
      console.log("1. Goals cargados del backend:", goalsData);
      
      if (goalsData) {
        // Aseguramos que exercise_mets y exercise_duration sean números
        const formattedGoals = {
          ...goalsData,
          exercise_mets: parseFloat(goalsData.exercise_mets) || 0,
          exercise_duration: parseInt(goalsData.exercise_duration) || 0
        };
        
        setGoals(formattedGoals);
        console.log("2. Goals formateados:", formattedGoals);
        
        // Calculamos las calorías quemadas
        let exerciseCalories = 0;
        if (formattedGoals.exercise_mets && formattedGoals.exercise_duration) {
          const weight = parseFloat(formattedGoals.weight) || 70;
          const mets = formattedGoals.exercise_mets; // Ya es número
          const duration = formattedGoals.exercise_duration; // Ya es número
          const hoursOfExercise = duration / 60;
          exerciseCalories = Math.round(mets * weight * hoursOfExercise);
        }

        setTdee(prev => {
          const newTdee = {
            ...prev,
            activityLevel: formattedGoals.lifestyle || '',
            exercise_mets: formattedGoals.exercise_mets,
            exercise_duration: formattedGoals.exercise_duration,
            exerciseCalories: exerciseCalories
          };
          console.log("3. TDEE actualizado:", newTdee);
          return newTdee;
        });
      }
    };

    getGoals();
  }, [email]);

  const handleGoalsChange = (e) => {
    const { name, value } = e.target;
    let newGoals = { ...goals, [name]: value };
    if (name === 'proteins' || name === 'carbohydrates' || name === 'fats') {
      const proteins = parseFloat(newGoals.proteins) || 0;
      const carbohydrates = parseFloat(newGoals.carbohydrates) || 0;
      const fats = parseFloat(newGoals.fats) || 0;
      newGoals.calories = (4 * proteins + 4 * carbohydrates + 9 * fats).toFixed(2);
    }
    setGoals(newGoals);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const goalsData = {
      proteins: parseFloat(goals.proteins),
      carbohydrates: parseFloat(goals.carbohydrates),
      fats: parseFloat(goals.fats),
      weight: parseFloat(goals.weight),
      height: parseFloat(goals.height),
      age: parseInt(goals.age),
      gender: goals.gender,
      body_fat: parseFloat(goals.body_fat),
      lifestyle: parseFloat(tdee.activityLevel),
      exercise_mets: parseFloat(tdee.exercise_mets),
      exercise_duration: parseInt(tdee.exercise_duration)
    };
    
    try {
      await apiSaveGoals(email, goalsData);
      alert('Goals saved successfully');
    } catch (error) {
      console.error('Error saving goals:', error);
    }
  };

  const handleBodyDataChange = (e) => {
    const { name, value } = e.target;
    setGoals(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const { weight, height, age, gender, body_fat } = goals;
    
    if (weight && height && age) {
      const mifflinBMR = gender === 'male' 
        ? (10 * weight) + (6.25 * height) - (5 * age) + 5
        : (10 * weight) + (6.25 * height) - (5 * age) - 161;

      let katchBMR = null;
      if (body_fat) {
        const leanMass = weight * (1 - (body_fat / 100));
        katchBMR = 370 + (21.6 * leanMass);
      }

      setBmr({
        mifflin: Math.round(mifflinBMR),
        katch: katchBMR ? Math.round(katchBMR) : null
      });
    }
  }, [goals]);

  useEffect(() => {
    if (bmr.mifflin && tdee.activityLevel) {
      const baseCalories = bmr.mifflin * parseFloat(tdee.activityLevel);
      setTdee(prev => ({
        ...prev,
        total: Math.round(baseCalories + prev.exerciseCalories)
      }));
    }
  }, [bmr.mifflin, tdee.activityLevel, tdee.exerciseCalories]);

  const handleActivityChange = (e) => {
    const { value } = e.target;
    setTdee(prev => ({
      ...prev,
      activityLevel: value
    }));
    setGoals(prev => ({
      ...prev,
      lifestyle: value
    }));
  };

  const handleExerciseChange = (e) => {
    const { name, value } = e.target;
    console.log("4. Evento recibido:", { name, value });
    
    setTdee(prev => {
      // Para exercise_mets, mantenemos el valor exacto del select
      const newValue = name === 'exercise_mets' ? value : 
                      name === 'exercise_duration' ? parseInt(value) : 
                      parseFloat(value) || 0;
      
      console.log("5. Valor procesado:", { 
        newValue, 
        type: typeof newValue, 
        original: value 
      });
      
      const newTdee = { 
        ...prev, 
        [name]: newValue
      };
      console.log("6. Nuevo TDEE antes de cálculos:", newTdee);
      
      if (newTdee.exercise_mets && newTdee.exercise_duration) {
        const weight = parseFloat(goals.weight) || 70;
        const mets = parseFloat(newTdee.exercise_mets); // Convertimos a número para el cálculo
        const duration = newTdee.exercise_duration; // Ya es número
        
        console.log("7. Valores para cálculo:", { weight, mets, duration });
        
        const hoursOfExercise = duration / 60;
        const caloriesBurned = Math.round(mets * weight * hoursOfExercise);
        
        newTdee.exerciseCalories = caloriesBurned;
        console.log("8. Calorías calculadas:", caloriesBurned);
      }
      
      setGoals(prev => {
        const newGoals = {
          ...prev,
          [name]: newValue
        };
        console.log("9. Nuevos goals:", newGoals);
        return newGoals;
      });
      
      return newTdee;
    });
  };

  const handleGoalChange = (e) => {
    const goal = e.target.value;
    const multiplier = goal === 'bulk' ? 1.15 : goal === 'cut' ? 0.85 : 1.0;
    
    // Usar Katch TDEE si está disponible, sino usar Mifflin TDEE
    const baseCalories = bmr.katch 
      ? Math.round(bmr.katch * parseFloat(tdee.activityLevel || 1) + tdee.exerciseCalories)
      : tdee.total;
      
    const targetCalories = Math.round(baseCalories * multiplier);
    
    setMacroCalculator(prev => ({
      ...prev,
      goal,
      targetCalories,
      // Valores iniciales según el objetivo
      proteins: goal === 'cut' ? 30 : goal === 'maintenance' ? 25 : 20,
      carbs: goal === 'cut' ? 35 : goal === 'maintenance' ? 40 : 45,
      fats: 35
    }));
  };

  const handleMacroChange = (macro, value) => {
    value = parseInt(value);
    
    setMacroCalculator(prev => {
      const newMacros = { ...prev };
      const oldValue = prev[macro];
      const difference = value - oldValue;
      
      // Si no hay cambio, retornamos el estado anterior
      if (difference === 0) return prev;
      
      // Definimos los límites para cada macro
      const limits = {
        proteins: {
          min: prev.goal === 'cut' ? 30 : prev.goal === 'maintenance' ? 25 : 20,
          max: prev.goal === 'cut' ? 35 : prev.goal === 'maintenance' ? 30 : 25
        },
        carbs: {
          min: prev.goal === 'cut' ? 35 : prev.goal === 'maintenance' ? 40 : 45,
          max: prev.goal === 'cut' ? 40 : prev.goal === 'maintenance' ? 45 : 50
        },
        fats: {
          min: 30,
          max: 35
        }
      };
      
      // Aseguramos que el valor del macro que se está cambiando esté dentro de sus límites
      newMacros[macro] = Math.min(Math.max(value, limits[macro].min), limits[macro].max);
      const actualDifference = newMacros[macro] - oldValue;
      
      // Ajustamos los otros macros proporcionalmente
      const othersToAdjust = ['proteins', 'carbs', 'fats'].filter(m => m !== macro);
      const totalOthers = othersToAdjust.reduce((sum, m) => sum + prev[m], 0);
      
      if (totalOthers > 0) {
        // Primera pasada: ajuste proporcional
        othersToAdjust.forEach(m => {
          const proportion = prev[m] / totalOthers;
          newMacros[m] = Math.round(prev[m] - (actualDifference * proportion));
          // Aseguramos que cada macro esté dentro de sus límites
          newMacros[m] = Math.min(Math.max(newMacros[m], limits[m].min), limits[m].max);
        });
        
        // Segunda pasada: ajuste final para asegurar 100%
        const total = newMacros.proteins + newMacros.carbs + newMacros.fats;
        if (total !== 100) {
          // Intentamos ajustar el macro que tenga más margen
          for (const m of othersToAdjust) {
            const currentValue = newMacros[m];
            const neededAdjustment = 100 - total;
            const potentialNewValue = currentValue + neededAdjustment;
            
            // Verificamos si el ajuste es posible dentro de los límites
            if (potentialNewValue >= limits[m].min && potentialNewValue <= limits[m].max) {
              newMacros[m] = potentialNewValue;
              break;
            }
          }
        }
      }
      
      return newMacros;
    });
  };

  const handleApplyMacros = () => {
    if (!macroCalculator.goal) return;

    const targetCalories = macroCalculator.targetCalories;
    const proteins = Math.round((targetCalories * macroCalculator.proteins / 100) / 4);
    const carbs = Math.round((targetCalories * macroCalculator.carbs / 100) / 4);
    const fats = Math.round((targetCalories * macroCalculator.fats / 100) / 9);

    setGoals(prev => ({
      ...prev,
      calories: targetCalories.toString(),
      proteins: proteins.toString(),
      carbohydrates: carbs.toString(),
      fats: fats.toString()
    }));

    // Crear un evento sintético para handleSubmit
    const event = new Event('submit');
    event.preventDefault = () => {}; // Añadir método preventDefault
    handleSubmit(event);
  };

  return (
    <div className="container mt-5">
      <div className="row mb-5">
        <div className="col-md-10 mx-auto">
          <form onSubmit={handleSubmit} className="goals-form">
            <div className="row align-items-end g-3">
              <div className="col-md-3">
                <label className="form-label">Calories</label>
                <input
                  type="number"
                  className="form-control"
                  name="calories"
                  value={goals.calories}
                  onChange={handleGoalsChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Proteins</label>
                <input
                  type="number"
                  className="form-control"
                  name="proteins"
                  value={goals.proteins}
                  onChange={handleGoalsChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Carbohydrates</label>
                <input
                  type="number"
                  className="form-control"
                  name="carbohydrates"
                  value={goals.carbohydrates}
                  onChange={handleGoalsChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Fats</label>
                <input
                  type="number"
                  className="form-control"
                  name="fats"
                  value={goals.fats}
                  onChange={handleGoalsChange}
                />
              </div>
              <div className="col-md-1">
                <button type="submit" className="btn-save-goals" title="Save Goals">
                  <i className="fas fa-save"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="input-panel">
            <h4 className="mb-4">BMR Calculator</h4>
            
            <div className="row align-items-end g-3 mb-4">
              <div className="col-md-2">
                <label className="form-label">Weight (kg)</label>
                <input
                  type="number"
                  className="form-control"
                  name="weight"
                  value={goals.weight || ''}
                  onChange={handleBodyDataChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Height (cm)</label>
                <input
                  type="number"
                  className="form-control"
                  name="height"
                  value={goals.height || ''}
                  onChange={handleBodyDataChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Age</label>
                <input
                  type="number"
                  className="form-control"
                  name="age"
                  value={goals.age || ''}
                  onChange={handleBodyDataChange}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Body Fat %</label>
                <input
                  type="number"
                  className="form-control"
                  name="body_fat"
                  value={goals.body_fat}
                  onChange={handleGoalsChange}
                  placeholder="Body Fat %"
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Gender</label>
                <select
                  className="form-select"
                  name="gender"
                  value={goals.gender || 'male'}
                  onChange={handleBodyDataChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-3">
                <div className="formula-panel text-center">
                  <h5>Mifflin-St Jeor Formula</h5>
                  <div className="formula-result mt-3">
                    <span className="result-value">
                      {bmr.mifflin ? `${bmr.mifflin} kcal/day` : 'Complete the form above'}
                    </span>
                    {goals.weight && goals.height && goals.age && (
                      <div className="formula-explanation">
                        {goals.gender === 'male' ? (
                          <>
                            <span className="formula-constant">10</span> × {' '}
                            <span className="formula-variable">{goals.weight}</span>
                            <span className="formula-unit">(kg)</span> {' '}+ {' '}
                            <span className="formula-constant">6.25</span> × {' '}
                            <span className="formula-variable">{goals.height}</span>
                            <span className="formula-unit">(cm)</span> {' '}- {' '}
                            <span className="formula-constant">5</span> × {' '}
                            <span className="formula-variable">{goals.age}</span>
                            <span className="formula-unit">(years)</span> {' '}+ {' '}
                            <span className="formula-constant">5</span>
                          </>
                        ) : (
                          <>
                            <span className="formula-constant">10</span> × {' '}
                            <span className="formula-variable">{goals.weight}</span>
                            <span className="formula-unit">(kg)</span> {' '}+ {' '}
                            <span className="formula-constant">6.25</span> × {' '}
                            <span className="formula-variable">{goals.height}</span>
                            <span className="formula-unit">(cm)</span> {' '}- {' '}
                            <span className="formula-constant">5</span> × {' '}
                            <span className="formula-variable">{goals.age}</span>
                            <span className="formula-unit">(years)</span> {' '}- {' '}
                            <span className="formula-constant">161</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="formula-panel text-center">
                  <h5>Katch-McArdle Formula</h5>
                  <div className="formula-result mt-3">
                    <span className="result-value">
                      {bmr.katch ? `${bmr.katch} kcal/day` : 'Complete the form above (including body fat)'}
                    </span>
                    {goals.weight && goals.body_fat && (
                      <div className="formula-explanation">
                        <span className="formula-constant">370</span> {' '}+ {' '}
                        <span className="formula-constant">21.6</span> × {' '}
                        <span className="formula-variable">
                          {(goals.weight * (1 - goals.body_fat/100)).toFixed(1)}
                        </span>
                        <span className="formula-unit">(kg lean mass)</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="input-panel">
            <h4 className="mb-4">TDEE Calculator</h4>
            
            {/* Factor de Actividad */}
            <div className="row align-items-end g-3 mb-4">
              <div className="col-md-12">
                <label className="form-label">Lifestyle</label>
                <select 
                  className="form-select" 
                  name="lifestyle" 
                  value={tdee.activityLevel || ''}
                  onChange={handleActivityChange}
                >
                  <option value="">Select your lifestyle</option>
                  <option value="1.3">Activity I (x1.3) - Seated/standing jobs: office workers, students, doctors, retail...</option>
                  <option value="1.55">Activity II (x1.55) - Light movement jobs: electricians, construction workers, farmers, carpenters...</option>
                  <option value="1.65">Activity III (x1.65) - Regular physical work: miners, metalworkers, heavy industry workers...</option>
                  <option value="2.0">Activity IV (x2.0) - Intense physical labor: lumberjacks, blacksmiths...</option>
                </select>
              </div>
            </div>

            {/* Ejercicio */}
            <div className="row g-3">
              <div className="col-md-5">
                <label className="form-label">Sport</label>
                <select 
                  className="form-select" 
                  name="exercise_mets" 
                  value={tdee.exercise_mets} 
                  onChange={handleExerciseChange}
                >
                  <option value="">Select sport</option>
                  
                  {/* Light Intensity (1-3 METs) */}
                  <optgroup label="Light Intensity">
                    <option value="2.0"><span className="multiplier">2.0 METs</span> - Walking (2.5 mph), Stretching</option>
                    <option value="2.5"><span className="multiplier">2.5 METs</span> - Playing with children (sitting/kneeling)</option>
                    <option value="3.0"><span className="multiplier">3.0 METs</span> - Walking (3.5 mph), Billiards</option>
                  </optgroup>
                  
                  {/* Moderate Intensity (3-6 METs) */}
                  <optgroup label="Moderate Intensity">
                    <option value="3.5"><span className="multiplier">3.5 METs</span> - Weight Training (light), Yoga, Cycling (slow)</option>
                    <option value="4.0"><span className="multiplier">4.0 METs</span> - Table Tennis, Volleyball (casual), Pilates</option>
                    <option value="4.3"><span className="multiplier">4.3 METs</span> - Walking (4.5 mph), Cycling (10-12 mph)</option>
                    <option value="4.5"><span className="multiplier">4.5 METs</span> - Basketball (shooting), Golf (carrying clubs)</option>
                    <option value="5.0"><span className="multiplier">5.0 METs</span> - Weight Training (vigorous), Running (5 mph)</option>
                    <option value="5.3"><span className="multiplier">5.3 METs</span> - Tai Chi, Dancing (ballroom)</option>
                    <option value="5.5"><span className="multiplier">5.5 METs</span> - Boxing (punching bag), Hiking</option>
                  </optgroup>
                  
                  {/* Vigorous Intensity (6-9 METs) */}
                  <optgroup label="Vigorous Intensity">
                    <option value="6.0"><span className="multiplier">6.0 METs</span> - Swimming (leisure), Rock Climbing</option>
                    <option value="6.8"><span className="multiplier">6.8 METs</span> - Cycling (14-16 mph), Cross-country skiing</option>
                    <option value="7.0"><span className="multiplier">7.0 METs</span> - Tennis, Soccer (casual), Circuit Training, Running (6 mph)</option>
                    <option value="7.3"><span className="multiplier">7.3 METs</span> - Squash, Rowing (vigorous)</option>
                    <option value="8.0"><span className="multiplier">8.0 METs</span> - Tennis (competitive), Volleyball (competitive)</option>
                    <option value="8.3"><span className="multiplier">8.3 METs</span> - Swimming (freestyle, fast)</option>
                    <option value="9.0"><span className="multiplier">9.0 METs</span> - Running (7 mph), Boxing (sparring)</option>
                  </optgroup>
                  
                  {/* High Intensity (>9 METs) */}
                  <optgroup label="High Intensity">
                    <option value="9.5"><span className="multiplier">9.5 METs</span> - Taekwondo</option>
                    <option value="9.8"><span className="multiplier">9.8 METs</span> - Judo</option>
                    <option value="10.0"><span className="multiplier">10.0 METs</span> - Soccer (competitive)</option>
                    <option value="10.3"><span className="multiplier">10.3 METs</span> - Karate/Kickboxing</option>
                    <option value="11.0"><span className="multiplier">11.0 METs</span> - Running (8 mph)</option>
                    <option value="12.3"><span className="multiplier">12.3 METs</span> - Running (9 mph)</option>
                  </optgroup>
                </select>
              </div>
              <div className="col-md-3">
                <label className="form-label">Duration (minutes)</label>
                <input
                  type="number"
                  className="form-control"
                  name="exercise_duration"
                  value={tdee.exercise_duration}
                  onChange={handleExerciseChange}
                  placeholder="Duration (minutes)"
                />
              </div>
              <div className="col-md-4 d-flex align-items-end">
                <div className="exercise-calories">
                  <span className="result-value exercise-result">
                    {tdee.exerciseCalories}
                  </span>
                  <span className="calories-label">calories burned</span>
                </div>
              </div>
            </div>

            {/* Resultado Final TDEE */}
            <div className="row mt-4">
              <div className="col-12">
                <div className="formula-panel text-center">
                  <h5>Total Daily Energy Expenditure</h5>
                  <div className="formula-result">
                    {/* TDEE basado en Mifflin */}
                    <div className="mb-3">
                      <div className="result-value">
                        {tdee.total || 0} kcal/day (Mifflin-St Jeor)
                      </div>
                      {bmr.mifflin && tdee.activityLevel && (
                        <div className="formula-explanation">
                          <span className="formula-variable">{bmr.mifflin}</span>
                          <span className="formula-unit">(BMR)</span> × {' '}
                          <span className="formula-variable">{tdee.activityLevel}</span>
                          <span className="formula-unit">(lifestyle)</span>
                          {tdee.exerciseCalories > 0 && (
                            <>
                              {' '}+ {' '}
                              <span className="formula-variable">{tdee.exerciseCalories}</span>
                              <span className="formula-unit">(exercise)</span>
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    {/* TDEE basado en Katch */}
                    {bmr.katch && (
                      <div>
                        <div className="result-value">
                          {/* GET= GER x FA + ejercicio físico. Ver Júlia Farré */}
                          {Math.round(bmr.katch * parseFloat(tdee.activityLevel || 1) + tdee.exerciseCalories)} kcal/day (Katch-McArdle)
                        </div>
                        {bmr.katch && tdee.activityLevel && (
                          <div className="formula-explanation">
                            <span className="formula-variable">{bmr.katch}</span>
                            <span className="formula-unit">(BMR)</span> × {' '}
                            <span className="formula-variable">{tdee.activityLevel}</span>
                            <span className="formula-unit">(lifestyle)</span>
                            {tdee.exerciseCalories > 0 && (
                              <>
                                {' '}+ {' '}
                                <span className="formula-variable">{tdee.exerciseCalories}</span>
                                <span className="formula-unit">(exercise)</span>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="input-panel">
            <h4 className="mb-4">Macro Calculator</h4>
            
            {/* Selector de objetivo y calorías objetivo */}
            <div className="row mb-3">
              <div className="col-md-3">
                <label className="form-label">Goal</label>
              </div>
              <div className="col-md-7">
                <select 
                  className="form-select"
                  value={macroCalculator.goal}
                  onChange={handleGoalChange}
                >
                  <option value="">Select your goal</option>
                  <option value="cut">Weight Loss (Cut)</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="bulk">Muscle Gain (Bulk)</option>
                </select>
              </div>
              <div className="col-md-2">
                {macroCalculator.goal && (
                  <span className="result-value">
                    {macroCalculator.targetCalories}
                    <small className="calories-label ms-2">kcal</small>
                  </span>
                )}
              </div>
            </div>

            {/* Sliders para macros */}
            {macroCalculator.goal && (
              <div className="macro-sliders">
                <div className="row mb-3">
                  <div className="col-md-3">
                    <label className="form-label">
                      Proteins 
                      <small className="text-muted d-block">
                        {macroCalculator.goal === 'cut' ? 'Range: 30-35%' : 
                         macroCalculator.goal === 'maintenance' ? 'Range: 25-30%' : 
                         'Range: 20-25%'}
                      </small>
                    </label>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="range"
                      className="form-range"
                      min={macroCalculator.goal === 'cut' ? 30 : 
                          macroCalculator.goal === 'maintenance' ? 25 : 20}
                      max={macroCalculator.goal === 'cut' ? 35 : 
                          macroCalculator.goal === 'maintenance' ? 30 : 25}
                      value={macroCalculator.proteins}
                      onChange={(e) => handleMacroChange('proteins', e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <span className="macro-percentage">
                      {macroCalculator.proteins}% 
                      <small className="text-muted ms-2">
                        ({Math.round((macroCalculator.targetCalories * macroCalculator.proteins / 100) / 4)}g)
                      </small>
                    </span>
                  </div>
                </div>

                {/* Carbohidratos */}
                <div className="row mb-3">
                  <div className="col-md-3">
                    <label className="form-label">
                      Carbs
                      <small className="text-muted d-block">
                        {macroCalculator.goal === 'cut' ? 'Range: 35-40%' : 
                         macroCalculator.goal === 'maintenance' ? 'Range: 40-45%' : 
                         'Range: 45-50%'}
                      </small>
                    </label>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="range"
                      className="form-range"
                      min={macroCalculator.goal === 'cut' ? 35 : 
                          macroCalculator.goal === 'maintenance' ? 40 : 45}
                      max={macroCalculator.goal === 'cut' ? 40 : 
                          macroCalculator.goal === 'maintenance' ? 45 : 50}
                      value={macroCalculator.carbs}
                      onChange={(e) => handleMacroChange('carbs', e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <span className="macro-percentage">
                      {macroCalculator.carbs}%
                      <small className="text-muted ms-2">
                        ({Math.round((macroCalculator.targetCalories * macroCalculator.carbs / 100) / 4)}g)
                      </small>
                    </span>
                  </div>
                </div>

                {/* Grasas */}
                <div className="row mb-3">
                  <div className="col-md-3">
                    <label className="form-label">
                      Fats
                      <small className="text-muted d-block">
                        Range: 30-35%
                      </small>
                    </label>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="range"
                      className="form-range"
                      min={30}
                      max={35}
                      value={macroCalculator.fats}
                      onChange={(e) => handleMacroChange('fats', e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <span className="macro-percentage">
                      {macroCalculator.fats}%
                      <small className="text-muted ms-2">
                        ({Math.round((macroCalculator.targetCalories * macroCalculator.fats / 100) / 9)}g)
                      </small>
                    </span>
                  </div>
                </div>

                {/* Total y botón para aplicar */}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <button 
                      className="btn btn-primary"
                      onClick={handleApplyMacros}
                    >
                      Apply These Macros
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyGoals;
