// If REACT_APP_API_URL is not set, use an empty string to use relative paths
// (this is the case in production)
const API_URL = process.env.REACT_APP_API_URL || '';

export const getAuthHeaders = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && !user.token) {
      console.warn('User found in localStorage but no token present');
    }
    return {
      'Content-Type': 'application/json',
      'Authorization': user && user.token ? `Bearer ${user.token}` : '',
    };
  } catch (error) {
    console.error('Error parsing user from localStorage:', error);
    return {
      'Content-Type': 'application/json'
    };
  }
};

// Función auxiliar para manejar las respuestas de fetch de manera consistente
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.error || 'Network response was not ok');
  }
  return await response.json();
};

export const apiLogFood = async (message, sender_id, date) => {
  const response = await fetch(`${API_URL}/log_food`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      message, 
      sender_id, 
      date 
    }),
  });
  return handleResponse(response);
};

export const apiLogFoodInMeal = async (meal_type, message, sender_id, date) => {
  const response = await fetch(`${API_URL}/log_food_in_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      sender_id, 
      meal_type, 
      message, 
      date 
    }),
  });
  return handleResponse(response);
};

{/* Unused function */}
export const apiGetFoodLogEntry = async (email, date, mealType, entryId) => {
  const response = await fetch(`${API_URL}/get_food_log_entry`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      entry_id: entryId 
    }),
  });
  return handleResponse(response);
};

export const apiClearMeal = async (email, meal_type, date) => {
  const response = await fetch(`${API_URL}/clear_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      sender_id: email, 
      meal_type, 
      date }),
  });
  return handleResponse(response);
};

export const apiGetOrCreateDayLog = async (email, date) => {
  const response = await fetch(`${API_URL}/get_or_create_day_log`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date 
    }),
  });
  return handleResponse(response);
};


export const apiSaveGoals = async (email, goals) => {
  const response = await fetch(`${API_URL}/save_goals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      goals }),
  });
  return handleResponse(response);
};

export const apiFetchGoals = async (email) => {
  const response = await fetch(`${API_URL}/get_goals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

// FoodInfo is FoodInfo plus frequency and calorie percentage
export const apiFetchFoodInfo = async (email) => {
  const response = await fetch(`${API_URL}/get_food_info`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateFoodInfo = async (email, id, updatedData) => {
  const response = await fetch(`${API_URL}/update_food_info`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      id, 
      updatedData 
    }),
  });
  return handleResponse(response);
};

//FoodData contains FoodInfo plus serving_quantity and source plus food name
export const apiUpdateFoodData = async (email, date, foodData) => {
  const response = await fetch(`${API_URL}/update_food_data`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      date,
      foodData 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateGenericFoodData = async (email, date, foodData) => {
  const response = await fetch(`${API_URL}/update_generic_food_data`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      date,
      foodData
    }),
  });
  return handleResponse(response);
};

export const apiUpdateGenericFoodDataFavorite = async (email, foodData) => {
  const response = await fetch(`${API_URL}/update_generic_food_favorite`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      foodData
    }),
  });
  return handleResponse(response);
};

export const apiUpdateFavoriteFoodData = async (email, foodData) => {
  const response = await fetch(`${API_URL}/update_favorite_food_data`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      foodData
    }),
  });
  return handleResponse(response);
};

export const apiUpdateFoodLogEntry = async (email, date, foodId, entryId, mealType, synonym, newQuantity, units) => {
  const response = await fetch(`${API_URL}/update_food_log_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      synonym: synonym,
      entry_id: entryId,
      food_id: foodId, 
      new_quantity: newQuantity,
      units: units
    }),
  });
  return handleResponse(response);
};


export const apiUpdateFavoriteMealEntry = async (email, mealId, entryId, synonym, newQuantity, units) => {
  const response = await fetch(`${API_URL}/update_favorite_meal_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId,
      entry_id: entryId,
      synonym: synonym,
      new_quantity: newQuantity,
      units: units
    }),
  });
  return handleResponse(response);  
};


export const apiDeleteFoodInfo = async (email, id, force) => {
  const response = await fetch(`${API_URL}/delete_food_info`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      id,
      force
    }),
  });
  return handleResponse(response);
};


export const apiLogout = async () => {
  const response = await fetch(`${API_URL}/logout`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  localStorage.removeItem('user');
  return handleResponse(response);
};

export const apiAddFavoriteMeal = async (email, alias, date, mealType) => {
  const response = await fetch(`${API_URL}/add_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      alias, 
      date, 
      meal_type: mealType 
    }),
  });
  return handleResponse(response);
};

export const apiGetFavoriteMeals = async (email) => {
  const response = await fetch(`${API_URL}/get_favorite_meals`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email 
    }),
  });
  return handleResponse(response);
};

export const apiDeleteFavoriteMeal = async (email, mealId) => {
  const response = await fetch(`${API_URL}/delete_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId 
    }),
  });
  return handleResponse(response);
};

export const apiLogFavoriteMeal = async (email, mealId, date, mealType) => {
  const response = await fetch(`${API_URL}/log_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({
      email, 
      meal_id: mealId, 
      date, 
      meal_type: mealType 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateFavoriteMealAlias = async (email, mealId, newAlias) => {
  const response = await fetch(`${API_URL}/update_favorite_meal_alias`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_id: mealId,
      new_alias: newAlias
    }),
  });
  return handleResponse(response);
};

export const apiAddGenericFoodData = async (email, date, foodData) => {
  const response = await fetch(`${API_URL}/add_generic_food_data`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date,
      foodData
    }),
  });
  return handleResponse(response);
};

export const apiAddManualEntry = async (email, date, mealType, name, macros, quantity, units) => {
  const response = await fetch(`${API_URL}/add_manual_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date, 
      meal_type: mealType, 
      name, 
      macros, 
      quantity, 
      units 
    }),
  });
  return handleResponse(response);
};

export const apiFetchMealNames = async (email) => {
  const response = await fetch(`${API_URL}/get_meal_names`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ email }),
  });
  return handleResponse(response);
};

export const apiUpdateMealNames = async (email, mealNames) => {
  const response = await fetch(`${API_URL}/update_meal_names`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      meal_names: mealNames 
    }),
  });
  console.log('Response (api.js):', response);
  return handleResponse(response);
};

export const apiGetUserEntries = async (email) => {
  const response = await fetch(`${API_URL}/get_user_entries`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({
      email
    })
  });
  return handleResponse(response);
};

export const apiSignUp = async (formData) => {  
  const response = await fetch(`${API_URL}/signup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });
  return await response;
};

export const apiLogin = async (loginData) => {
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginData),
    });
    
    return response;
};

export const apiAnalyseDaylog = async (email, date, totalMacros, macrosGoals) => {  
  const response = await fetch(`${API_URL}/analyse_daylog`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      date,
      total_macros: totalMacros,
      macros_goals: macrosGoals
    }),
  });
  return handleResponse(response);
};

export const apiAnalyseWeek = async (email, date) => {
  const response = await fetch(`${API_URL}/analyse_week`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      date
    }),
  });
  return handleResponse(response);
};

export const apiAskMrFit = async (email, date, dayLog, totalMacros, macrosGoals, question) => {
  const response = await fetch(`${API_URL}/ask_mr_fit`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ email, date, dayLog, totalMacros, macrosGoals, question }),
  });
  return handleResponse(response);
};

export const apiGetDatesWithEntries = async (email) => {
    const response = await fetch(`${API_URL}/get_dates_with_entries`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ 
            email 
        }),
    });
    return handleResponse(response);
};

export const apiAddFoodListToFavoriteMeal = async (email, mealId, text) => {
  const response = await fetch(`${API_URL}/add_food_list_to_favorite_meal`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      meal_id: mealId,
      text
    }),
  });
  return handleResponse(response);
};

export const apiDeleteFavoriteMealEntry = async (email, entryId) => {
  const response = await fetch(`${API_URL}/delete_favorite_meal_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email,
      entry_id: entryId
    }),
  });
  return handleResponse(response);
};


export const apiDeleteFoodLogEntry = async (email, date, entryId) => {
  const response = await fetch(`${API_URL}/delete_food_log_entry`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ 
      email, 
      date, 
      entry_id: entryId 
    }),
  });
  return handleResponse(response);
};

export const apiUpdateGenericMacros = async (email, date, entry_id, macros, custom_name) => {
    const response = await fetch(`${API_URL}/update_generic_macros`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({
            email,
            date,
            entry_id,
            macros,
            custom_name
        })
    });
    return handleResponse(response);
};

export const apiTranscribeAudio = async (audioBlob, email) => {
  if (!audioBlob) {
    throw new Error('No audio blob provided');
  }

  // Debug info
  console.log('Audio blob size:', audioBlob.size);
  console.log('Audio blob type:', audioBlob.type);

  const formData = new FormData();
  formData.append('audio', audioBlob, 'recording.webm'); // Cambiado a .webm
  formData.append('email', email);

  // Debug info
  console.log('FormData entries:');
  for (let [key, value] of formData.entries()) {
    console.log(key, ':', value);
  }

  const response = await fetch(`${API_URL}/transcribe_audio`, {
    method: 'POST',
    // Quitamos el Content-Type para que el navegador lo establezca automáticamente con el boundary correcto
    headers: {
      'Authorization': getAuthHeaders().Authorization
    },
    body: formData
  });
  return handleResponse(response);
};