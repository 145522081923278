import React, { useState } from 'react';
import voiceService from '../services/voiceService';
import { apiTranscribeAudio } from '../api';
import AudioVisualizer from './AudioVisualizer';
import RecordingTimer from './RecordingTimer';

function MealInput({ mealType, value, suggestion, onInputChange, onKeyDown, onVoiceInput, userEmail }) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);

  // Dividimos el valor actual por comas para manejar múltiples entradas
  const entries = value.split(',');
  const currentInput = entries[entries.length - 1].trim();

  // Obtenemos el texto antes de la entrada actual
  const valueBeforeCurrentInput = value.substring(0, value.lastIndexOf(currentInput));

  const handleKeyDown = (e) => {
    if (suggestion && (e.key === 'Tab' || e.key === 'ArrowRight')) {
      e.preventDefault();
      onKeyDown(mealType, e);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      // Emitimos un evento personalizado para el Enter
      onKeyDown(mealType, { ...e, isEnter: true });
    }
  };

  const handleVoiceButtonClick = async () => {
    if (isRecording) {
      setIsRecording(false);
      setAudioStream(null);
      try {
        const audioBlob = await voiceService.stopRecording();
        const data = await apiTranscribeAudio(audioBlob, userEmail);
        onVoiceInput(mealType, data.text);
      } catch (error) {
        console.error('Error al procesar audio:', error);
        alert(`Error al procesar el audio: ${error.error || error.message}`);
      }
    } else {
      try {
        const stream = await voiceService.requestMicrophonePermission();
        if (stream) {
          setAudioStream(stream);
          const started = await voiceService.startRecording((error) => {
            alert(error);
            setIsRecording(false);
            setAudioStream(null);
          });
          if (started) {
            setIsRecording(true);
          }
        }
      } catch (error) {
        console.error('Error al iniciar grabación:', error);
        setIsRecording(false);
        setAudioStream(null);
      }
    }
  };

  return (
    <div className="input-container">
      <input
        type="text"
        placeholder={isRecording ? '' : 'Describe your meal to mr. fit.'}
        value={value}
        onChange={(e) => onInputChange(mealType, e)}
        onKeyDown={handleKeyDown}
        className={`form-control ${isRecording ? 'recording' : ''}`}
      />
      <button 
        onClick={handleVoiceButtonClick}
        className="btn btn-icon voice-input-button"
        title={isRecording ? "Stop recording" : "Start recording"}
      >
        {isRecording ? (
          <i className="fas fa-stop-circle text-danger"></i>
        ) : (
          <i className="fas fa-microphone"></i>
        )}
      </button>
      {suggestion && !isRecording && (
        <div className="inline-suggestion">
          {valueBeforeCurrentInput}
          {currentInput}
          <span className="suggested-text">
            {suggestion}
          </span>
        </div>
      )}
      <AudioVisualizer isRecording={isRecording} stream={audioStream} />
      {isRecording && (
        <div className="recording-controls">
          <RecordingTimer isRecording={isRecording} />
        </div>
      )}
    </div>
  );
}

export default MealInput;